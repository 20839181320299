import CoinProtocol from "./pages/coin/CoinProtocol";
import Admins from "./pages/Admin";
import LoginLogs from "./pages/LoginLog";
import Users from "./pages/User";
import UserWallets from "./pages/user/UserWallet";
import Docs from "./pages/content/Doc";
import WebPages from "./pages/content/WebPage";
import DocCategories from "./pages/content/DocCategory";
import Settings from "./pages/setting/setting";
import GoodsCategory from "./pages/goods/GoodsCategory";
import Goods from "./pages/goods/Goods";
import GoodsOrder from "./pages/goods/GoodsOrder";

import SiteResource from "./pages/content/SiteResource";
import SiteNotification from "./pages/content/SiteNotification";
import PayTasks from "./pages/task/PayTasks";
import UserLevelSetting from "./pages/user/UserLevelSetting";
import UserHead from "./pages/content/UserHead";
import Protocol from "./pages/coin/Protocol";
import PayChannel from "./pages/setting/PayChannel";
import SysModule from "./pages/sys/SysModule";
import SysRole from "./pages/sys/SysRole";
import { MenuItem } from "./model/menu";
import UserBankCard from "./pages/user/UserBankCard";

export const menuItems: MenuItem[] = [
  {
    name: "会员管理",
    code: "userManager",
    icon: Users.icon,
    children: [
      {
        name: "会员列表",
        icon: Users.icon,
        code: "users",
        href: "/users",
      },
      {
        name: "账务记录",
        code: "userWalletLog",
      },
      {
        name: "会员等级",
        icon: UserLevelSetting.icon,
        code: "userLevelSetting",
        href: "/userLevelSetting",
      },
      {
        name: "会员关系网",
        icon: Users.icon,
        code: "userByGroup",
        href: "/userByGroup",
      },
      {
        name: "钱包管理",
        icon: UserWallets.icon,
        code: "userWallets",
        href: "/userWallets",
      },
      {
        name: "连单",
        icon: PayTasks.icon,
        code: "payTasks",
        href: "/payTasks",
      },
      {
        name: "银行卡",
        icon: UserBankCard.icon,
        code: "userBankCard",
        href: "/userBankCard",
      },
    ],
  },
  {
    name: "交易",
    icon: Goods.icon,
    code: "exchange",
    children: [
      {
        name: "订单列表",
        icon: GoodsCategory.icon,
        code: "order",
        href: "/order",
      },
      {
        name: "提现列表",
        icon: GoodsCategory.icon,
        code: "withdrawRecord",
        href: "/withdrawRecord",
      },
      {
        name: "风控信息",
        icon: GoodsCategory.icon,
        code: "userRisk",
        href: "/userRisk",
      },
    ],
  },
  {
    name: "商品管理",
    icon: Goods.icon,
    code: "goodsManager",
    children: [
      {
        name: "商品分类",
        icon: GoodsCategory.icon,
        code: "goodsCategories",
        href: "/goodsCategories",
      },
      {
        name: "商品",
        icon: Goods.icon,
        code: "goods",
        href: "/goods",
      },
      {
        name: "商品订单",
        icon: GoodsOrder.icon,
        code: "goodsOrders",
        href: "/goodsOrders",
      },
    ],
  },
  {
    name: "内容管理",
    icon: Docs.icon,
    code: "contentManager",
    children: [
      {
        name: "文章管理",
        icon: Docs.icon,
        code: "docs",
        href: "/docs",
      },
      {
        name: "文章分类",
        icon: DocCategories.icon,
        code: "docCategories",
        href: "/docCategories",
      },
      {
        name: "页面信息",
        icon: WebPages.icon,
        code: "webPages",
        href: "/webPages",
      },
      {
        name: "通知管理",
        icon: SiteNotification.icon,
        code: "siteNotification",
        href: "/siteNotification",
      },
    ],
  },
  {
    name: "系统设置",
    icon: Settings.icon,
    code: "sysSetting",
    children: [
      {
        name: "站点资源",
        icon: SiteResource.icon,
        code: "siteResource",
        href: "/siteResource",
      },
      {
        name: "站点设置",
        icon: Settings.icon,
        code: "settings.update",
        href: "/settings/SiteSetting",
      },
      {
        name: "支付设置",
        icon: Settings.icon,
        code: "settings.update",
        href: "/settings/PaySetting",
      },
      {
        name: "支付通道设置",
        icon: PayChannel.icon,
        code: "payChannels",
        href: "/payChannels",
      },
      {
        name: "邮件推送设置",
        icon: Settings.icon,
        code: "settings.update",
        href: "/settings/EmailSetting",
      },
      {
        name: "电报机器人配置",
        icon: Settings.icon,
        code: "telBotConfigs",
        href: "/telBotConfigs",
      },
      {
        name: "客服人员链接",
        icon: Settings.icon,
        code: "customerSupport",
        href: "/customerSupport",
      },
      {
        name: "用户头像管理",
        icon: UserHead.icon,
        code: "userHead",
        href: "/userHead",
      },
      {
        name: "系统设置表单",
        code: "settings",
      },
    ],
  },
  {
    name: "管理员",
    icon: Admins.icon,
    code: "adminManager",
    children: [
      {
        name: "管理员",
        icon: Admins.icon,
        code: "admins",
        href: "/admins",
      },
      {
        name: "角色",
        icon: SysRole.icon,
        code: "sysRoles",
        href: "/sysRoles",
      },
      {
        name: "功能权限",
        icon: SysModule.icon,
        code: "sysModules",
        href: "/sysModules",
      },
      {
        name: "登录记录",
        icon: LoginLogs.icon,
        code: "loginLogs",
        href: "/loginLogs",
      },
      {
        name: "币种管理",
        icon: CoinProtocol.icon,
        code: "coins",
        href: "/coins",
      },
      {
        name: "协议网络",
        icon: Protocol.icon,
        code: "protocols",
        href: "/protocols",
      },
    ],
  },
];
