import { Box, BoxProps, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DashboardJsonView } from "../model/DashboardJsonView";
import { DashboardApi } from "../api/DashboardApi";
import { UserManagerApi, UserModel } from "../api/UserManagerApi";
import { RichTreeView, TreeViewBaseItem } from "@mui/x-tree-view";

const CardTitleDesc = styled(Box)<BoxProps>(({ theme }) => ({
  fontSize: "26px",
  lineHeight: "36px",
  fontWeight: "bold",
}));

const CardBoldDesc = styled(Box)<BoxProps>(({ theme }) => ({
  display: "inline",
  fontSize: "20px",
  lineHeight: "100%",
  fontWeight: "bold",
}));

function StatisticsCard() {
  const theme = useTheme();
  const [record, setRecord] = useState<DashboardJsonView>();
  const [treeData, setTreeData] = useState<TreeViewBaseItem[]>([
    {
      id: "all",
      label: "全部",
    },
  ]);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [lastSelectedItem, setLastSelectedItem] = React.useState<string>("all");

  const handleItemSelectionToggle = (
    event: React.SyntheticEvent,
    itemId: string,
    isSelected: boolean
  ) => {
    if (isSelected) {
      setLastSelectedItem(itemId);
    }
  };

  useEffect(() => {
    agentChange(lastSelectedItem);
  }, [lastSelectedItem]);

  const agentChange = async (agentId: string) => {
    if ("all" === lastSelectedItem) {
      const res = await DashboardApi.getDashboardData();
      setRecord(res);
    } else {
      const res = await DashboardApi.getByAgentId(agentId);
      setRecord(res);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const allAgent = await UserManagerApi.getAllAgent();
      setExpandedItems(allAgent?.map((a) => a.id) || []);
      const treeTmpData = toTree(allAgent);
      if (treeTmpData) {
        setTreeData([...treeData, ...treeTmpData]);
      }
      await agentChange("all");
    };
    fetchData();
  }, []);

  function toTree(agents?: UserModel[]): TreeViewBaseItem[] | undefined {
    const getAgentChild = (
      parentId?: string
    ): TreeViewBaseItem[] | undefined => {
      return agents
        ?.filter((agent) => agent.parentUserId === parentId)
        .map(
          (agent) =>
            ({
              id: agent.id,
              label: agent.name,
              children: getAgentChild(agent.id),
            } as TreeViewBaseItem)
        );
    };
    return getAgentChild(undefined);
  }

  return (
    <Box display={"flex"} marginTop={"48px"}>
      <Box sx={{ minWidth: "180px" }}>
        <RichTreeView
          items={treeData}
          expandedItems={expandedItems}
          selectedItems={lastSelectedItem}
          onItemSelectionToggle={handleItemSelectionToggle}
        />
      </Box>
      <Box paddingLeft={2}>
        <Grid spacing={2} container color="white">
          <Grid item xs={3}>
            <Stack
              spacing={1}
              padding={4}
              sx={{
                backgroundColor: theme.palette.primary.main,
              }}
            >
              <Box>总充值人数</Box>
              <CardTitleDesc>
                {record?.totalStatistics?.totalRechargeUsers}
              </CardTitleDesc>
              <Box>
                今日首冲人数：{" "}
                <CardBoldDesc>
                  {record?.currDailyStatistics?.newRechargeUsers}
                </CardBoldDesc>
              </Box>
              <Box>
                昨日首冲人数：{" "}
                {record?.yesterdayDailyStatistics?.newRechargeUsers}
              </Box>
              <Box>====================</Box>
              <Box>
                今日充值人数：{" "}
                <CardBoldDesc>
                  {record?.currDailyStatistics?.rechargeUsers}
                </CardBoldDesc>
              </Box>
              <Box>
                昨日首冲人数： {record?.yesterdayDailyStatistics?.rechargeUsers}
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack
              spacing={1}
              padding={4}
              sx={{
                backgroundColor: theme.palette.secondary.main,
              }}
            >
              <Box>总充值金额</Box>
              <CardTitleDesc>
                {record?.totalStatistics?.totalRechargeAmount}
              </CardTitleDesc>
              <Box>
                今日首冲金额：{" "}
                <CardBoldDesc>
                  {record?.currDailyStatistics?.newRechargeAmount}
                </CardBoldDesc>
              </Box>
              <Box>
                昨日首冲金额：{" "}
                {record?.yesterdayDailyStatistics?.newRechargeAmount}
              </Box>
              <Box>====================</Box>
              <Box>
                今日充值金额：{" "}
                <CardBoldDesc>
                  {record?.currDailyStatistics?.rechargeAmount}
                </CardBoldDesc>
              </Box>
              <Box>
                昨日充值金额：{" "}
                {record?.yesterdayDailyStatistics?.rechargeAmount}
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack
              spacing={1}
              padding={4}
              sx={{
                backgroundColor: theme.palette.success.main,
              }}
            >
              <Box>用户总量</Box>
              <CardTitleDesc>
                {record?.totalStatistics?.totalUsers}
              </CardTitleDesc>
              <Box>
                今日新增：{" "}
                <CardBoldDesc>
                  {record?.currDailyStatistics?.users}
                </CardBoldDesc>
              </Box>
              <Box>昨日新增： {record?.yesterdayDailyStatistics?.users}</Box>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack
              spacing={1}
              padding={4}
              sx={{
                backgroundColor: theme.palette.warning.main,
              }}
            >
              <Box>订单总量</Box>
              <CardTitleDesc>
                {record?.totalStatistics?.totalOrders}
              </CardTitleDesc>
              <Box>
                今日做单人数：{" "}
                <CardBoldDesc>
                  {record?.currDailyStatistics?.makeOrderUsers}
                </CardBoldDesc>
              </Box>
              <Box>
                昨日做单人数：{" "}
                {record?.yesterdayDailyStatistics?.makeOrderUsers}
              </Box>
              <Box>====================</Box>
              <Box>
                今日新增订单：{" "}
                <CardBoldDesc>
                  {record?.currDailyStatistics?.makeOrders}
                </CardBoldDesc>
              </Box>
              <Box>
                昨日新增订单： {record?.yesterdayDailyStatistics?.makeOrders}
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack
              spacing={1}
              padding={4}
              sx={{
                backgroundColor: theme.palette.info.main,
              }}
            >
              <Box>订单总佣金</Box>
              <CardTitleDesc>
                {record?.totalStatistics?.commissionAmount}
              </CardTitleDesc>
              <Box>
                今日新增佣金：{" "}
                <CardBoldDesc>
                  {record?.currDailyStatistics?.commissionAmount}
                </CardBoldDesc>
              </Box>
              <Box>
                昨日新增佣金：{" "}
                {record?.yesterdayDailyStatistics?.commissionAmount}
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack
              spacing={1}
              padding={4}
              sx={{
                backgroundColor: theme.palette.error.main,
              }}
            >
              <Box>提现人数</Box>
              <CardTitleDesc>
                {record?.totalStatistics?.totalWithdrawUsers}
              </CardTitleDesc>
              <Box>
                今日首提人数：
                <CardBoldDesc>
                  {record?.currDailyStatistics?.newWithdrawUsers}
                </CardBoldDesc>
              </Box>
              <Box>
                昨日首提人数：{" "}
                {record?.yesterdayDailyStatistics?.newWithdrawUsers}
              </Box>
              <Box>====================</Box>
              <Box>
                今日首提金额：{" "}
                <CardBoldDesc>
                  {record?.currDailyStatistics?.newWithdrawAmount}
                </CardBoldDesc>
              </Box>
              <Box>
                昨日首提金额：{" "}
                {record?.yesterdayDailyStatistics?.newWithdrawAmount}
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack
              spacing={1}
              padding={4}
              sx={{
                backgroundColor: theme.palette.warning.main,
              }}
            >
              <Box>用户提现金额</Box>
              <CardTitleDesc>
                {record?.totalStatistics?.totalRechargeAmount}
              </CardTitleDesc>
              <Box>
                今日提现人数：
                <CardBoldDesc>
                  {record?.currDailyStatistics?.withdrawUsers}
                </CardBoldDesc>
              </Box>
              <Box>
                今日提现金额： {record?.currDailyStatistics?.withdrawAmount}
              </Box>
              <Box>====================</Box>
              <Box>
                昨日提现人数：{" "}
                <CardBoldDesc>
                  {record?.yesterdayDailyStatistics?.withdrawUsers}
                </CardBoldDesc>
              </Box>
              <Box>
                昨日提现金额：{" "}
                {record?.yesterdayDailyStatistics?.withdrawAmount}
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default StatisticsCard;
