import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import { CreditCard } from "@mui/icons-material";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { MyShow } from "../../components/core/MyShow";
import { MyShowFields } from "../../components/MyShowFields";
import { BooleanTypeChoices } from "../../model/Core";

const fields: MyField[] = [
  Field.objectId(
    {},
    {
      isList: false,
    }
  ),
  Field.userField(),
  Field.userLevelField("currUserLevelId", "currUserLevelId"),
  Field.userPayTaskField("draftPayTasksId", "draftPayTasksId"),
  Field.editSelect({
    label: "是否冻结",
    source: "freeze",
    choices: BooleanTypeChoices,
    helperText: "请修改冻结的订单， 除非订单状态是正常的",
  }),
  Field.text({
    label: "级别轮次",
    source: "totalPayTasksCount",
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
];

const resource = {
  list: () => {
    return <MyList fields={fields} rowActions={[]} />;
  },
  show: () => {
    return (
      <MyShow>
        <MyShowFields fields={fields} />
      </MyShow>
    );
  },
  create: (record: any, onSuccess: (data: any, variables: any) => void) => {
    return (
      <MyCreate
        resource={"userMakeOrderState"}
        fields={fields}
        record={record}
        redirect={false}
        mutationOptions={{ onSuccess: onSuccess }}
      />
    );
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <CreditCard />,
};

export default resource;
