/** @jsxRuntime classic */
import "./polyfill";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "proxy-polyfill";
// IE11 needs "jsxRuntime classic" for this initial file which means that "React" needs to be in scope
// https://github.com/facebook/create-react-app/issues/9906
import * as React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { LicenseInfo } from "@mui/x-data-grid-pro";

LicenseInfo.setLicenseKey(
  "e0d9bb8070ce0054c9d9ecb6e82cb58fTz0wLEU9MzI0NzIxNDQwMDAwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y"
);
ReactDOM.render(<App />, document.getElementById("root"));
