if (!Array.prototype.at) {
  Array.prototype.at = function (index) {
    // 转换负索引为正索引
    index = Math.trunc(index) || 0;
    if (index < 0) index += this.length;
    // 检查索引是否在数组范围内
    if (index < 0 || index >= this.length) return undefined;
    // 返回相应的元素
    return this[index];
  };
}
export {};
