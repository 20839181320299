import { DeleteButton, EditButton, WrapperField } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { NftGoodsStatusChoices } from "../../model/goods";
import { UpDownButton } from "./UpDownButton";

const fields: MyField[] = [
  Field.text({
    label: "ID",
    source: "id",
    isCreate: false,
    isUpdate: false,
    isEdit: false,
    isFilter: false,
  }),
  {
    label: "上下架",
    source: "status1",
    component: WrapperField,
    isFilter: true,
    props: {
      children: <UpDownButton />,
    },
  },
  Field.editSelect({
    label: "状态",
    isEdit: false,
    choices: NftGoodsStatusChoices,
    source: "status",
    isList: false,
  }),
  Field.ref({
    label: "分类",
    source: "categoryId",
    isRequired: true,
    isList: true,
    reference: "goodsCategories",
    referenceLabel: "title",
    originSource: "categoryId",
    translateLabel: (record: any) => record?.title?.[0].value,
  }),
  Field.localesValue({
    label: "标题",
    source: "title",
    fullWidth: true,
    isRequired: true,
    isFilter: false,
  }),
  Field.editAmount({
    label: "价格",
    source: "price",
  }),
  Field.img({
    label: "主图",
    source: "logo",
  }),
  Field.richText({
    label: "详情",
    source: "content",
    fullWidth: true,
    isFilter: false,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        pagination={false}
        fields={fields}
        rowActions={[<EditButton />, <DeleteButton />]}
        // groupingColDef={groupingColDef}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <PhotoLibraryIcon />,
};

export default resource;
