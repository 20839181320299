import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import CollectionsIcon from "@mui/icons-material/Collections";
import { Button, Chip, Stack } from "@mui/material";
import {
  PayChannelStatus,
  WithdrawApproveStatus,
  WithdrawRecordModel,
} from "../../model/WithdrawRecordModel";
import { toString } from "../../utils/amountUtils";
import MakePayBtn from "../../components/withdraw/makePayBtn";
import RejectPayBtn from "../../components/withdraw/rejectPayBtn";
import ManualPayBtn from "../../components/withdraw/manualPayBtn";
import RiskListDialogBtn from "../../components/order/RiskListDialogBtn";

function getChipByState(state?: WithdrawApproveStatus) {
  if (state && state === "OK") {
    return <Chip color={"success"} label={"审核通过"} />;
  } else if (state && state === "BACK") {
    return <Chip color={"error"} label={"审核驳回"} />;
  } else {
    return <Chip variant={"outlined"} label={"代审核"} />;
  }
}

function getChipByPayChannelState(channelState?: PayChannelStatus) {
  if (channelState && channelState === "OK") {
    return <Chip color={"success"} label={"转账成功"} />;
  } else if (channelState && channelState === "ERROR") {
    return <Chip color={"error"} label={"转账失败"} />;
  } else if (channelState && channelState === "COMMIT") {
    return <Chip variant={"filled"} label={"已提交"} />;
  } else {
    return <Chip variant={"outlined"} label={"未提交"} />;
  }
}

const fields: MyField<WithdrawRecordModel>[] = [
  Field.text({
    label: "订单号",
    source: "id",
    minWidth: 80,
  }),
  Field.functionField({
    label: "订单号 / 提现类型",
    source: "withdrawNo",
    minWidth: 210,
    render: (record, source) => {
      return `${record.withdrawNo} / ${record.withdrawType}`;
    },
  }),
  Field.functionField({
    label: "用户信息",
    source: "userId",
    minWidth: 180,
    render: (record, source) => {
      return (
        <>
          <div>{`${record.user.name} [${record.user.uid}]`}</div>
          <div>{`${record.user.phone}`}</div>
          <div>{`VIP${record.user.identityLevel}`}</div>
        </>
      );
    },
  }),
  Field.functionField({
    label: "风险",
    source: "user.riskScore",
    minWidth: 90,
    render: (record, source) => {
      return (
        <RiskListDialogBtn
          userId={record.userId}
          riskScore={record.user.riskScore}
        />
      );
    },
  }),
  Field.functionField({
    label: "提现金额",
    source: "withdrawAmount",
    minWidth: 120,
    render: (record, source) => {
      return (
        <>
          <div>{`${record.symbol} ${toString(record.withdrawAmount)}`}</div>
          <div>{`${record.withdrawFee}%`}</div>
          <div>{`${record.symbol} ${toString(
            record.confirmWithdrawAmount
          )}`}</div>
        </>
      );
    },
  }),
  Field.functionField({
    label: "银行信息",
    source: "userBankCard",
    minWidth: 180,
    render: (record, source) => {
      return (
        <>
          <div>{`${record.userBankCard.recipientCountry}`}</div>
          <div>{`${record.userBankCard.symbol}`}</div>
          <div>{`${record.userBankCard.accountName}`}</div>
          <div>{`${record.userBankCard.accountNumber}`}</div>
          <div>{`${record.userBankCard.accountCode}`}</div>
          <div>{`${record.userBankCard.enterpriseName}`}</div>
          <div>{`${record.userBankCard.cashierInformation}`}</div>
          <div>{`${record.userBankCard.firstName} ${record.userBankCard.lastName}`}</div>
        </>
      );
    },
  }),
  Field.functionField({
    label: "发起/处理/回调",
    source: "createdDate",
    minWidth: 180,
    render: (record, source) => {
      return (
        <>
          <div>{`${record.createdDate}`}</div>
          <div>{`${record.handleDate || "-"}`}</div>
          <div>{`${record.callbackDate || "-"}`}</div>
        </>
      );
    },
  }),
  // Field.functionField({
  //   label: "代理审核",
  //   source: "oneProxyApprove",
  //   render: (record, source) => {
  //     return (
  //       <>
  //         <div>{getChipByState(record.oneProxyApprove)}</div>
  //         <div>{getChipByState(record.twoProxyApprove)}</div>
  //       </>
  //     );
  //   },
  // }),
  Field.functionField({
    label: "三方状态",
    source: "payChannelState",
    render: (record, source) => {
      return (
        <>
          <div>{getChipByPayChannelState(record.payChannelState)}</div>
          <div>{record.remarks}</div>
        </>
      );
    },
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        fields={fields}
        renderActionCell={(params) => {
          if (!params.row.recordEnd) {
            return (
              <Stack direction={"row"} spacing={1}>
                <MakePayBtn record={params.row} />
                <RejectPayBtn record={params.row} />
                <ManualPayBtn record={params.row} />
              </Stack>
            );
          }
        }}
      />
    );
  },
  icon: <CollectionsIcon />,
};

export default resource;
