import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import CollectionsIcon from "@mui/icons-material/Collections";
import { OrderModel } from "../../model/order";
import { Chip, Stack } from "@mui/material";
import FreezeBtn from "../../components/order/FreezeBtn";
import { DeleteWithConfirmButton } from "react-admin";
import ConfirmPayBtn from "../../components/order/ConfirmPayBtn";
import OrderFlowerBtn from "../../components/order/OrderFlowerBtn";
import UnfreezeBtn from "../../components/order/UnfreezeBtn";
import SkipUnfreezeBtn from "../../components/order/SkipUnfreezeBtn";

const fields: MyField<OrderModel>[] = [
  Field.text({
    label: "订单号",
    source: "id",
  }),
  Field.functionField({
    label: "用户",
    source: "userId",
    isFilter: false,
    minWidth: 136,
    render: (record, source) => {
      return (
        <>
          <div>{`${record.user?.name} [VIP${record.userMakeOrderState?.currUserLevelId}]`}</div>
          <div>{`${record.user?.phone}`}</div>
        </>
      );
    },
  }),
  Field.editAmount({
    label: "订单价格",
    source: "orderAmount",
  }),
  Field.editAmount({
    label: "佣金",
    source: "commissionAmount",
  }),
  Field.editAmount({
    label: "补金金额",
    source: "supplementAmount",
  }),
  Field.functionField({
    label: "级别/连单ID",
    source: "userMakeOrderState",
    isFilter: false,
    minWidth: 218,
    render: (record, source) => {
      return (
        <>
          <div>{`[VIP${record.userMakeOrderState?.currUserLevelId}]`}</div>
          <div>{record.userMakeOrderState?.id}</div>
        </>
      );
    },
  }),
  Field.functionField({
    label: "下单时间/完结时间",
    source: "createdDate",
    minWidth: 188,
    isFilter: false,
    render: (record, source) => {
      return (
        <>
          <div>{record.createdDate}</div>
          <div>{record.completeDate || "-"}</div>
        </>
      );
    },
  }),
  Field.functionField({
    label: "订单状态",
    source: "orderStatus",
    render: (record, source) => {
      if (record.orderStatus === "OK") {
        return <Chip size={"small"} color="success" label={"已支付"} />;
      }
      if (record.orderStatus === "INIT") {
        return <Chip size={"small"} label={"未支付"} />;
      }
      if (record.orderStatus === "FREEZE") {
        return (
          <Chip
            size={"small"}
            variant="outlined"
            color="error"
            label={"冻结"}
          />
        );
      }
    },
  }),
  Field.text({
    label: "冻结原因",
    source: "freezeMsg",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        fields={fields}
        renderActionCell={(params) => {
          if (params.row.orderStatus === "OK") {
            return <OrderFlowerBtn record={params.row} />;
          }
          if (params.row.orderStatus === "INIT") {
            return (
              <Stack direction={"row"} spacing={1}>
                <ConfirmPayBtn record={params.row} />
                <FreezeBtn record={params.row} />
                <DeleteWithConfirmButton />
              </Stack>
            );
          }
          if (params.row.orderStatus === "FREEZE") {
            return (
              <Stack direction={"row"} spacing={1}>
                <UnfreezeBtn record={params.row} />
                <SkipUnfreezeBtn record={params.row} />
              </Stack>
            );
          }
        }}
      />
    );
  },
  icon: <CollectionsIcon />,
};

export default resource;
