import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import { CreditCard } from "@mui/icons-material";
import { MyDatagrid } from "../../components/core/MyDatagrid";
import { UserBankCard } from "../../model/UserBankCard";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { MyShow } from "../../components/core/MyShow";
import { MyShowFields } from "../../components/MyShowFields";

const fields: MyField<UserBankCard>[] = [
  Field.objectId(
    {},
    {
      isList: false,
    }
  ),
  Field.userField(),
  Field.text({
    label: "收款国家",
    source: "recipientCountry",
    isFilter: false,
  }),
  Field.text({
    label: "币种",
    source: "symbol",
  }),
  Field.text({
    label: "账户名",
    source: "accountName",
  }),
  Field.text({
    label: "账户号 / IBAN",
    source: "accountNumber",
  }),
  Field.text({
    label: "SWIFT/BIC",
    source: "accountCode",
    isFilter: false,
  }),
  Field.text({
    label: "企业名称",
    source: "enterpriseName",
    isFilter: false,
  }),
  Field.text({
    label: "收款方信息",
    source: "cashierInformation",
    isFilter: false,
  }),
  Field.text({
    label: "名",
    source: "firstName",
    isFilter: false,
  }),
  Field.text({
    label: "姓",
    source: "lastName",
    isFilter: false,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

export const UserBankCardCom = () => {
  return <MyDatagrid fields={fields} rowActions={[]} />;
};

const resource = {
  list: () => {
    return <MyList fields={fields} rowActions={[]} />;
  },
  show: () => {
    return (
      <MyShow>
        <MyShowFields fields={fields} />
      </MyShow>
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <CreditCard />,
};

export default resource;
