import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import { DeleteButton, EditButton, required, SelectInput } from "react-admin";
import { MyList } from "../../components/core/MyList";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { HeadsetMic as HeadsetMicIcon } from "@mui/icons-material";
import React from "react";
import { EditSelectField } from "../../components/EditSelectField";
import {
  BooleanTypeChoices,
  CustomerSupportsTypeChoices,
  StatusTypeChoices,
} from "../../model/Core";

const fields: MyField[] = [
  Field.text({
    label: "ID",
    source: "id",
    isCreate: false,
    isUpdate: false,
    isEdit: false,
    isFilter: false,
  }),
  Field.text({
    label: "名称",
    source: "name",
    isRequired: true,
    isFilter: false,
  }),
  Field.editSelect({
    label: "链接类型",
    source: "supportType",
    choices: CustomerSupportsTypeChoices,
    defaultValue: false,
  }),
  Field.editSelect({
    label: "商城是否展示",
    source: "shopShow",
    choices: BooleanTypeChoices,
    defaultValue: false,
  }),
  Field.text({
    label: "链接",
    source: "url",
    isFilter: false,
    helperText: `
      https://t.me/{Username} 不需要@符号，  
      https://wa.me/{phone} 注意手机号要带上区号， 并且不要任何空格和符号
    `,
  }),
  {
    label: "状态",
    source: "status",
    component: EditSelectField,
    isFilter: false,
    props: {
      choices: StatusTypeChoices,
      alwaysOn: true,
    },
    filterProps: {
      choices: StatusTypeChoices,
      alwaysOn: true,
    },
    isCreate: true,
    isEdit: true,
    isUpdate: true,
    editComponent: SelectInput,
    editProps: {
      defaultValue: "NORMAL",
      choices: StatusTypeChoices,
      idate: [required()],
    },
  },
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        pagination={false}
        fields={fields}
        rowActions={[<EditButton />, <DeleteButton />]}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <HeadsetMicIcon />,
};

export default resource;
