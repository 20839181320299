import polyglotI18nProvider from "ra-i18n-polyglot";
import { Admin, CustomRoutes, memoryStore } from "react-admin";
import { Route } from "react-router";

import Polyglot from "node-polyglot";
import authProvider from "./authProvider";
import { API_URL } from "./config";
import Configuration from "./configuration/Configuration";
import HttpDataProvider from "./dataProvider/HttpDataProvider";
import chineseMessages from "./i18n/ch";
import { Layout, Login } from "./layout";
import { lightTheme } from "./layout/themes";

import Admins from "./pages/Admin";
import LoginLogs from "./pages/LoginLog";
import Users from "./pages/User";
import UserByGroup from "./pages/UserByGroup";
import UserWallets from "./pages/user/UserWallet";

import GoodsCategory from "./pages/goods/GoodsCategory";
import Goods from "./pages/goods/Goods";
import GoodsOrder from "./pages/goods/GoodsOrder";

import Docs from "./pages/content/Doc";
import WebPages from "./pages/content/WebPage";
import DocCategories from "./pages/content/DocCategory";

import Settings from "./pages/setting/setting";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TelBotConfig from "./pages/setting/TelBotConfig";
import DevSettings from "./pages/devSetting/devSettings";
import SiteResource from "./pages/content/SiteResource";
import SiteNotification from "./pages/content/SiteNotification";
import PayTasks from "./pages/task/PayTasks";
import UserLevelSetting from "./pages/user/UserLevelSetting";
import UserHead from "./pages/content/UserHead";
import Coin from "./pages/coin/Coin";
import CoinProtocol from "./pages/coin/CoinProtocol";
import Protocol from "./pages/coin/Protocol";
import PayChannel from "./pages/setting/PayChannel";
import { createMenuContext } from "./hook/MenuCentext";
import { menuItems } from "./MenuData";
import { MyResource } from "./components/core/MyResource";
import SysModule from "./pages/sys/SysModule";
import SysRole from "./pages/sys/SysRole";
import { ConfirmDialogProvider } from "./hook/ConfirmContext";
import UserBankCard from "./pages/user/UserBankCard";
import UserPrickNeedle from "./pages/user/UserPrickNeedle";
import UserMakeOrderState from "./pages/user/UserMakeOrderState";
import Order from "./pages/order/Order";
import WithdrawRecord from "./pages/order/WithdrawRecord";
import { MyDashboard1 } from "./dashboard1";
import UserRisk from "./pages/order/UserRisk";
import MyAdmin from "./components/core/MyAdmin";
import CustomerSupport from "./pages/setting/CustomerSupport";

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    return chineseMessages;
  },
  "ch",
  {
    onMissingKey: (
      key: string,
      options: Polyglot.InterpolationOptions,
      locale: string
    ) => key,
  }
);

// function NewComponent() {
//   let notify = useNotify();
//
//   console.log(notify);
//   debugger;
//
//   window.addEventListener("error", (error) => {
//     console.log("捕获到异常：", error.error);
//     notify(error.error);
//   });
//   window.addEventListener("unhandledrejection", (error) => {
//     console.log("捕获到异常：", error.reason.message);
//     notify(error.reason.message);
//   });
//
//   return <div></div>;
// }

const App = () => {
  const { Provider: MenuProvider } = createMenuContext(menuItems);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MenuProvider>
        <MyAdmin
          title="管理后台"
          dataProvider={HttpDataProvider(API_URL)}
          authProvider={authProvider}
          dashboard={MyDashboard1}
          loginPage={Login}
          layout={Layout}
          i18nProvider={i18nProvider}
          disableTelemetry
          store={memoryStore()}
          theme={lightTheme}
        >
          <CustomRoutes>
            <Route path="/configuration" element={<Configuration />} />
          </CustomRoutes>
          <MyResource name="admins" {...Admins} />
          <MyResource name="users" {...Users} />
          <MyResource name="userByGroup" {...UserByGroup} />
          <MyResource name="userBankCard" {...UserBankCard} />
          <MyResource name="userLevelRecord" {...UserBankCard} />
          <MyResource name="userPrickNeedle" {...UserPrickNeedle} />
          <MyResource name="userMakeOrderState" {...UserMakeOrderState} />
          <MyResource name="userRisk" {...UserRisk} />

          <MyResource name="order" {...Order} />
          <MyResource name="withdrawRecord" {...WithdrawRecord} />

          <MyResource name="userWallets" {...UserWallets} />
          <MyResource name="loginLogs" {...LoginLogs} />
          <MyResource name="docs" {...Docs} />
          <MyResource name="userHead" {...UserHead} />
          <MyResource name="webPages" {...WebPages} />
          <MyResource name="docCategories" {...DocCategories} />
          <MyResource name="settings" {...Settings} />
          <MyResource name="devSettings" {...DevSettings} />
          <MyResource name="sysModules" {...SysModule} />
          <MyResource name="sysRoles" {...SysRole} />

          <MyResource name="customerSupport" {...CustomerSupport} />

          <MyResource name="goodsCategories" {...GoodsCategory} />
          <MyResource name="goods" {...Goods} />
          <MyResource name="goodsOrders" {...GoodsOrder} />

          <MyResource name="payChannels" {...PayChannel} />
          <MyResource name="telBotConfigs" {...TelBotConfig} />
          <MyResource name="siteResource" {...SiteResource} />
          <MyResource name="siteNotification" {...SiteNotification} />
          <MyResource name="payTasks" {...PayTasks} />
          <MyResource name="userLevelSetting" {...UserLevelSetting} />
          <MyResource name="coins" {...Coin} />
          <MyResource name="coinProtocols" {...CoinProtocol} />
          <MyResource name="protocols" {...Protocol} />
        </MyAdmin>
      </MenuProvider>
    </LocalizationProvider>
  );
};

export default App;
