import { buildField } from "../Field";
import { MyField } from "../../components/types";
import { MyAmountField } from "../../components/MyAmountField";
import { NumberInput, TextField, TextInput } from "react-admin";
import { stringToNumber, toStringNotSymbol } from "../amountUtils";
import { TextArgs } from "./text";
import { base, BaseArgs } from "./base";

export interface EditAmountArgs extends BaseArgs {
  transform?: (record?: any) => any;
}

const defaultArgs: Partial<EditAmountArgs> = {
  isRequired: false,
  fullWidth: false,
  isFilter: false,
  sortable: false,
  isCreate: true,
  isEdit: true,
  defaultValue: 0,
  isUpdate: true,
};

export function editAmount(args: EditAmountArgs, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: MyAmountField,
      editComponent: NumberInput,
      filterProps: {
        alwaysOn: true,
        clearAlwaysVisible: true,
        resettable: true,
      },
      editProps: {
        min: 0,
        parse: (value: string) => {
          console.log("parse:", value);
          return stringToNumber(value);
        },
        format: (value: any) => {
          console.log("format:", value);
          return toStringNotSymbol(value);
        },
      },
    },
    field
  );
}
