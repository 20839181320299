import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";
import { Admin, LoginRecord } from "../model/Admin";

export const AccountApi = {
  login: async (
    username: string,
    password: string,
    isRobotAdmin: boolean
  ): Promise<LoginRecord> => {
    let { data } = await fetchJson(`${API_URL}/account/loginByType`, {
      method: "POST",
      body: new URLSearchParams({
        account: username,
        password,
        isRobotAdmin: isRobotAdmin.toString(),
      }),
    });
    return data;
  },
  getCurrent: async (): Promise<Admin> => {
    let { data } = await fetchJson(`${API_URL}/account`);
    return data;
  },
  logout: async (): Promise<void> => {
    await fetchJson(`${API_URL}/account/logout`, {
      method: "POST",
    });
  },
};
